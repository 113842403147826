<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="nickname"></el-input>
      <el-select v-model="workTime" clearable placeholder="请选择班次">
        <el-option label="早班" value="早班"></el-option>
        <el-option label="中班" value="中班"></el-option>
        <el-option label="晚班" value="晚班"></el-option>
      </el-select>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <el-upload action="http://39.106.92.106:9090/user/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="username" label="用户名" width="140"></el-table-column>
      <el-table-column label="角色">
        <template v-slot="scope">
          <span>{{ roles.find(v => v.flag === scope.row.role) ? roles.find(v => v.flag === scope.row.role).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" width="120"></el-table-column>
<!--      <el-table-column prop="email" label="邮箱"></el-table-column>-->
      <el-table-column prop="phone" label="电话"></el-table-column>
      <el-table-column label="工时">
        <template v-slot="scope">
          <span>{{ formatTime(scope.row.workHour)  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="今日工时">
        <template v-slot="scope">
          <span>{{ formatTime(scope.row.dailyHour)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="workTime" label="班次"></el-table-column>
      <el-table-column label="小组">
        <template v-slot="scope">
          <span>{{ groups.find(v => v.id === scope.row.groupId) ? groups.find(v => v.id === scope.row.groupId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态"></el-table-column>
      <el-table-column label="操作"  width="300" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="showChart(scope.row.id)" size="small">
            查看报表
          </el-button>
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="用户信息" :visible.sync="dialogFormVisible" width="30%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="ID" v-if="false">
          <el-input v-model="form.id" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="form.username" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="form.nickname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select clearable v-model="form.role" placeholder="请选择角色" style="width: 100%">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="工时">
          <el-input v-model="form.workHour" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="今日工时">
          <el-input v-model="form.dailyHour" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="班次">
          <el-select clearable v-model="form.workTime" placeholder="请选择班次" style="width: 100%">
            <el-option label="早班" value="早班"></el-option>
            <el-option label="中班" value="中班"></el-option>
            <el-option label="晚班" value="晚班"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属小组">
          <el-select clearable v-model="form.groupId" placeholder="请选择所属小组" style="width: 100%">
            <el-option v-for="item in groups" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable v-model="form.state" placeholder="请选择状态" style="width: 100%">
            <el-option label="维修中" value="维修中"></el-option>
            <el-option label="休假中" value="休假中"></el-option>
            <el-option label="空闲中" value="空闲中"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="维修工报表" :visible.sync="dialogFormVisible2" width="60%" >
      <el-card style="width: 100%">
        <div ref="lineChart" class="chart"></div>
      </el-card>
      <el-card style="width: 100%">
        <div ref="lineChart2" class="chart"></div>
      </el-card>
      <el-card style="width: 100%">
        <div ref="lineChart3" class="chart"></div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import * as echarts from "echarts";

export default {
  name: "Maintainer",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      nickname: "",
      workTime: "",
      requestId: null,
      form: {},
      maintainers: [],
      dialogFormVisible: false,
      dialogFormVisible2: false,
      multipleSelection: [],
      roles: [],
      groups: [],
      baseURLdata: request.defaults.baseURL,
      options: [{
        value: 'ROLE_MAINTAINER',
        label: '维修工'
      }],
    }
  },
  created() {
    this.loadState()
    this.load()
  },
  methods: {
    load() {
      this.request.get("/user/findmaintainer", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          nickname: this.nickname,
          workTime: this.workTime,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/role").then(res => {
        this.roles = res.data
      })
      this.request.get("/mtgroup").then(res => {
        this.groups = res.data
      })
      this.saveState()
    },
    saveState() {
      const state = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        nickname: this.nickname,
        workTime: this.workTime,
      };
      sessionStorage.setItem('MaintainerState', JSON.stringify(state));
    },
    loadState() {
      const savedState = sessionStorage.getItem('MaintainerState');
      if (savedState) {
        const { nickname, workTime, pageNum, pageSize } = JSON.parse(savedState);
        this.nickname = nickname || '';
        this.workTime = workTime || '';
        this.pageNum = pageNum || 1;
        this.pageSize = pageSize || 10;
      }
    },
    save() {
      this.request.post("/user", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    // 渲染折线图
    renderLineChart(userId) {
      this.request.get('/dailyHourLog/findOneByUserId/' + userId)
          .then(response => {
            console.log('Response:', response);
            const daypro = response.data;
            if (daypro && Array.isArray(daypro)) {
              const dates = daypro.map(item => item.updateTime);
              const counts = daypro.map(item => item.dailyHour);

              this.$nextTick(() => {
                const lineChart = echarts.init(this.$refs.lineChart);
                const option = {
                  title: {
                    text: '工时记录:'
                  },
                  xAxis: {
                    type: 'category',
                    data: dates
                  },
                  yAxis: {
                    type: 'value',
                    axisLabel: {
                      formatter: (value) => Math.floor(value)
                    },
                    minInterval: 1
                  },
                  series: [{
                    data: counts,
                    type: 'line',
                    label: {
                      show: true,
                      position: 'top',
                      formatter: '{c}'
                    }
                  }]
                };
                lineChart.setOption(option);
              });
            } else {
              console.error('Unexpected data format:', daypro);
            }
          })
          .catch(error => {
            console.error('Failed to fetch data:', error);
          });
    },
    renderLineChart2(userId) {
      this.request.get('/failureRecord/getChartByUserId/' + userId)
          .then(response => {
            console.log('Response:', response);
            const daypro = response.data;
            if (daypro && Array.isArray(daypro)) {
              const dates = daypro.map(item => item.updateTime);
              const counts = daypro.map(item => item.closeRate);

              this.$nextTick(() => {
                const lineChart2 = echarts.init(this.$refs.lineChart2);
                const option = {
                  title: {
                    text: '工单关闭率:'
                  },
                  xAxis: {
                    type: 'category',
                    data: dates
                  },
                  yAxis: {
                    type: 'value',
                    axisLabel: {
                      formatter: (value) => `${Math.floor(value)}%`
                    },
                    minInterval: 1
                  },
                  series: [{
                    data: counts,
                    type: 'line',
                    label: {
                      show: true,
                      position: 'top',
                      formatter: '{c}'
                    }
                  }]
                };
                lineChart2.setOption(option);
              });
            } else {
              console.error('Unexpected data format:', daypro);
            }
          })
          .catch(error => {
            console.error('Failed to fetch data:', error);
          });
    },
    renderLineChart3(userId) {
      this.request.get('/componentUpdate/getChartData/' + userId)
          .then(response => {
            const chartData = response.data;
            const times = chartData.map(item => item.takeTime);
            const counts = chartData.map(item => item.count);
            const prices = chartData.map(item => item.price);
            // ECharts 配置
            const myChart = echarts.init(this.$refs.lineChart3);
            const option = {
              title: {
                text: '领用备件'
              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: ['数量', '价格']
              },
              xAxis: {
                type: 'category',
                data: times
              },
              yAxis: [
                {
                  type: 'value',
                  name: '数量',
                  min: 0,
                  position: 'left'
                },
                {
                  type: 'value',
                  name: '价格',
                  min: 0,
                  position: 'right'
                }
              ],
              series: [
                {
                  name: '数量',
                  type: 'bar',
                  data: counts,
                  itemStyle: {
                    color: '#42a5f5' // 柱子颜色
                  }
                },
                {
                  name: '价格',
                  type: 'line',
                  yAxisIndex: 1,
                  data: prices,
                  itemStyle: {
                    color: '#ff7043' // 线条颜色
                  },
                  label: {
                    show: true,
                    position: 'top'
                  }
                }
              ]
            };
            myChart.setOption(option);
        })
        .catch(error => {
          console.error('Failed to fetch data:', error);
        });
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
    },
    showChart(userId){
      this.renderLineChart(userId);
      this.renderLineChart2(userId);
      this.renderLineChart3(userId);
      this.dialogFormVisible2 = true
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    del(id) {
      this.request.delete("/user/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    formatTime(hours) {
      // 将小时数格式化为天时分秒的字符串显示
      const remainingHours = Math.floor(hours % 24);
      const minutes = Math.floor((hours * 60) % 60);
      return `${remainingHours}小时${minutes}分钟`;
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/user/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.nickname = ""
      this.workTime = ""
      this.load()
      sessionStorage.removeItem('MaintainerState');
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    exp() {
      window.open(this.baseURLdata+"/user/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee !important;
}
.chart {
  width: 900px;
  height: 400px;  /* 或者根据需要设置 */
}

</style>
